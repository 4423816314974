import * as yup from 'yup';
import { monthOptionsNumberValue } from 'const/options';

export const EASY_AUTO_REFILL_SCHEMA = yup.object().shape({
    dobMonth: yup.string().required(),
    dobDay: yup.string().required(),
    dobYear: yup
        .string()
        .required()
        .test('dobYear', 'Future', function (value) {
            if (this.parent.dobMonth && this.parent.dobDay && value) {
                // get the month name
                const monthSelected = monthOptionsNumberValue.find((month) => month.value === this.parent.dobMonth);
                return Date.parse(`${monthSelected?.label} ${this.parent.dobDay}, ${value}`) <= Date.now();
            } else {
                return true;
            }
        }),
    zipcode: yup.string().required()
});
