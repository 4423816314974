// General
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Hooks
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// UI-kit
import PageSection from 'ui-kit/page-section/page-section';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { JoinMembershipPlanForm } from 'components/join-membership-plan-form';
// Components & UI
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';

import { refreshUnauthenticatedSession } from 'state/account/account.reducers';
// State management & Helpers
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { joinMembershipPlanActions } from 'state/join-membership-plan/join-membership-plan.reducer';
import {
    joinMembershipPlanGetSecretKeyHashRoutine,
    joinMembershipPlanVerifyUserRoutine
} from 'state/join-membership-plan/join-membership-plan.routines';
import {
    joinMembershipPlanSecurityTokenSelector,
    joinMembershipPlanUserBearerTokenSelector
} from 'state/join-membership-plan/join-membership-plan.selectors';

import { VerifyUserResponse } from 'types/auto-refill';

import { getPhoneNumber } from 'util/globalVariables';
import { formatBirthDate } from 'util/string';

// Styles
import './join-membership-plan.style.scss';

const JoinMembershipPlan = ({
    location,
    data
}: {
    location: Location;
    data: GatsbyTypes.JoinMembershipPlanDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { joinMembershipPlanWoman, backgroundImage } = data;
    const securityToken = useSelector(joinMembershipPlanSecurityTokenSelector);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const params = new URLSearchParams(location.search);
    const securityTokenParameter = params.get('securityToken');

    useEffect(() => {
        if (securityTokenParameter) {
            dispatch(joinMembershipPlanActions.setSecurityToken({ securityToken: securityTokenParameter }));
        } else {
            navigate('/link-expired?flow=join-membership-plan');
        }
    }, [dispatch, securityTokenParameter]);

    // Once security token is set in redux, get the secret key hash value to be used when authenticating (after verification)
    useEffect(() => {
        if (securityToken) {
            // To validate the security token we run a verifyUser request
            // which will inform us if the token is valid
            dispatch(
                joinMembershipPlanVerifyUserRoutine.trigger({
                    dateOfBirth: '',
                    zipcode: '',
                    onSuccess: () => {
                        // If token is not expired, get hash
                        dispatch(
                            joinMembershipPlanGetSecretKeyHashRoutine.trigger({
                                // If invalid hash, return expired
                                onFailure: () => {
                                    navigate('/link-expired?flow=join-membership-plan');
                                }
                            })
                        );
                    },
                    onFailure: () => {
                        // If token expired redirect to expired link
                        navigate('/link-expired?flow=join-membership-plan');
                    },
                    onMaxNumAttemptsReached: () => {
                        navigate('/verification-failed?flow=join-membership-plan');
                    }
                })
            );
        }
    }, [dispatch, securityToken]);

    const handleShowErrorModal = useCallback(
        (error: VerifyUserResponse) => {
            dispatch(
                openModal({
                    showClose: false,
                    className: 'custom-join-membership-plan-modal modal-dialog-centered',
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.joinMembershipPlanFailure.title')} icon="alert" />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={error.messageText}
                            note={t('modals.joinMembershipPlanFailure.footNotes', {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.joinMembershipPlanFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'JoinMembershipPlanNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleSubmit = useCallback(
        (formData) => {
            const { dobDay, dobMonth, dobYear, zipcode } = formData;
            setIsSubmitting(true);
            dispatch(
                joinMembershipPlanVerifyUserRoutine.trigger({
                    dateOfBirth: formatBirthDate(dobYear, dobMonth, dobDay),
                    zipcode: zipcode,
                    onSuccess: () => {
                        dispatch(refreshUnauthenticatedSession());
                    },
                    onFailure: (error: VerifyUserResponse) => {
                        setIsSubmitting(false);
                        handleShowErrorModal(error);
                    },
                    onMaxNumAttemptsReached: () => {
                        setIsSubmitting(false);
                        navigate('/verification-failed?flow=join-membership-plan');
                    }
                })
            );
        },
        [dispatch, handleShowErrorModal]
    );

    const joinMembershipPlanBearerToken = useSelector(joinMembershipPlanUserBearerTokenSelector);
    useEffect(() => {
        if (joinMembershipPlanBearerToken) {
            navigate('/join-membership-plan/welcome');
        }
    }, [joinMembershipPlanBearerToken]);

    return (
        <MarketingPageLayout backgroundImage={backgroundImage} metaData={{}}>
            <PageSection>
                <Container fluid className={'join-membership-plan'} id={'join-membership-plan'}>
                    <Row>
                        <Col sm="12" lg={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col lg="8" className="join-membership-plan-form-container">
                                    <p className="h5 join-membership-plan-eyebrow text-left">
                                        {t('pages.joinMembershipPlan.userVerification.eyebrow')}
                                    </p>
                                    <h1 className="join-membership-plan-title text-left">
                                        {t('pages.joinMembershipPlan.userVerification.title')}
                                    </h1>

                                    <div className="spacer text-left mx-0 d-block">&nbsp;</div>

                                    <p className="h4 join-membership-plan-form-instructions text-left">
                                        {t('pages.joinMembershipPlan.userVerification.instructions')}
                                    </p>

                                    <JoinMembershipPlanForm
                                        onSubmit={(e) => handleSubmit(e)}
                                        formName="JoinMembershipPlanForm"
                                        isSubmittingForm={isSubmitting}
                                    />
                                </Col>
                                <Col
                                    className="join-membership-plan-image-container"
                                    xs={{ span: 8, offset: 0 }}
                                    sm={{ span: 6, offset: 3 }}
                                    lg={{ span: 4, offset: 0 }}
                                >
                                    {joinMembershipPlanWoman && (
                                        <GatsbyImage
                                            image={getImage(joinMembershipPlanWoman as FileNode) as IGatsbyImageData}
                                            alt={'join-membership-plan-form-image'}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default JoinMembershipPlan;

export const query = graphql`
    query JoinMembershipPlanData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        joinMembershipPlanWoman: file(relativePath: { eq: "assets/images/smiling-red-haired-woman.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
